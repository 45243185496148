export const AuthConstants = {
  KEYS: {
    USERNAME: "username",
    NAME: "name",
    ACCESS_TOKEN: "access_token",
    REFRESH_TOKEN: "refresh_token",
    AUTHORITIES: "authorities",
    UMPIRE_ID: "umpire_id"
  },
  USER_ROLES: {
    ZE_UMPIRE: "ZE_UMPIRE",
    ZE_SUPER_UMPIRE: "ZE_SUPER_UMPIRE",
    ZE_BOC_USER: "ZE_BOC_USER",
    ZE_UMPIRE_SUPERVISOR: "ZE_UMPIRE_SUPERVISOR",
    ZE_BOC_ADMIN: "ZE_BOC_ADMIN",
    ZE_AUDITOR: "ZE_AUDITOR",
    ZE_AUDITOR_ADMIN: "ZE_AUDITOR_ADMIN",
    ZE_SUPER_ADMIN: "ZE_SUPER_ADMIN",
    ZE_MINOR_UMPIRE: "ZE_MINOR_UMPIRE",
    ZE_UMPIRE_CALLUP: "ZE_UMPIRE_CALLUP",
    ZE_AFL_UMPIRE: "ZE_AFL_UMPIRE",
  },
  COOKIES: {
    SAML_COOKIE: "JSESSIONID"
  }
};
