import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DropdownItem from "../elements/DropdownItem";
import { NavigationLinkNarrow, NewNavigationLinkNarrow } from "./NavigationLinkNarrow";
import { AuthConstants } from "../login/AuthConstants";
import mlbLogo from "../../assets/logo/mlb-logo@2x.png";
import navlogo from "../../assets/logo/nav-logo@2x.png";
import HotkeysModal from "../hotkeys/HotkeysModal";
import { useAuth } from "../../contexts/AuthContext";

const NavbarOuter = styled.div.attrs({
  className: "d-flex flex-row justify-content-between"
})`
  background: ${props => props.backgroundColor} no-repeat right 0 top 0;
  background-image: url(${mlbLogo});
  background-size: 47px 24px;
  border-bottom: 1px solid #002e6d;
  height: 24px;
  z-index: 1031 !important;

  .btn-link {
    color: white;
    font-family: Helvetica;
    :hover {
      color: white;
      text-decoration: none;
    }
  }
`;

const NavbarSignout = styled.span.attrs({
  className: "justify-content-end"
})``;

const NavbarSignoutButton = styled.button.attrs({
  "aria-haspopup": "true",
  "aria-expanded": "false",
  className: "btn btn-sm btn-link",
  "data-toggle": "dropdown",
  type: "button"
})`
  letter-spacing: -0.3px !important;
  line-height: normal !important;
  margin-right: 50px;
  min-height: 24px;
  max-height: 24px;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
`;
const NavbarSignoutLink = DropdownItem.extend`
  z-index: 1051 !important;
`;

const NavigationLinks = styled.span.attrs({
  className: "justify-content-start"
})`
  padding-left: 35px;
`;

const NavLogoImg = styled.img`
  padding: 0;
  position: relative;
  z-index: 1000;
`;

const NavLogoLink = styled(Link)`
  margin-left: 15px;
  padding: 0;
  position: absolute;
`;

const ExternalLink = styled.a.attrs({ className: "ml-3" })`
  color: white;
  font-size: 14px;
  font-weight: 400;
  height: 17px;
  padding: 0 !important;

  &:hover {
    color: white;
  }
`;

const NavbarNarrow = ({ rootStore, name }) => {
  const { loggedIn, logout, userInfo, containsAuthorities } = useAuth();

  const isSuperAdmin = () => {
    return containsAuthorities(userInfo.authorities, [AuthConstants.USER_ROLES.ZE_SUPER_ADMIN]);
  };

  const hasDisputesPermission = () => {
    return containsAuthorities(userInfo.authorities, [
      AuthConstants.USER_ROLES.ZE_SUPER_ADMIN,
      AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
      AuthConstants.USER_ROLES.ZE_BOC_ADMIN,
      AuthConstants.USER_ROLES.ZE_SUPER_UMPIRE,
      AuthConstants.USER_ROLES.ZE_UMPIRE,
      AuthConstants.USER_ROLES.ZE_UMPIRE_SUPERVISOR,
      AuthConstants.USER_ROLES.ZE_UMPIRE_CALLUP,
      AuthConstants.USER_ROLES.ZE_AFL_UMPIRE,
    ]);
  };

  const hasReportsPermission = () => {
    return containsAuthorities(userInfo.authorities, [
      AuthConstants.USER_ROLES.ZE_SUPER_ADMIN,
      AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
      AuthConstants.USER_ROLES.ZE_BOC_ADMIN
    ]);
  };

  const hasSuperUmpireView = () => {
    return containsAuthorities(userInfo.authorities, [
      AuthConstants.USER_ROLES.ZE_SUPER_ADMIN,
      AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
      AuthConstants.USER_ROLES.ZE_BOC_ADMIN,
      AuthConstants.USER_ROLES.ZE_BOC_USER,
      AuthConstants.USER_ROLES.ZE_UMPIRE_SUPERVISOR
    ]);
  };

  const hotkeys = () => {
    rootStore.commonStore.setShowHotkeysModal(true);
  };

  if (loggedIn) {
    return (
      <NavbarOuter backgroundColor="#002e6d">
        <NavLogoLink to="/schedule" newApp>
          <NavLogoImg src={navlogo} alt="ZE Logo" height="30px;" width="26px;" />
        </NavLogoLink>
        <NavigationLinks>
          <NewNavigationLinkNarrow to="/schedule" name="Schedule" />
          {hasDisputesPermission() ? <NavigationLinkNarrow to="/dispute" name="Disputes" /> : null}
          {hasReportsPermission() ? <NavigationLinkNarrow to="/reports" name="Reports" /> : null}
          {/* {hasSuperUmpireView && <NavigationLinkNarrow to="/superUmpire" name="Umpire" />} */}
          {hasReportsPermission() ? (
            <ExternalLink
              href="https://prod-useast-b.online.tableau.com/#/site/mlb/workbooks/1242015/views"
              target="_blank"
            >
              Tableau
            </ExternalLink>
          ) : null}
          {isSuperAdmin() && <NewNavigationLinkNarrow to="/permissions" name="Permissions" />}
        </NavigationLinks>
        <NavbarSignout>
          <NavbarSignoutButton id="dropdownMenuButton">
            {rootStore.authStore?.name}&nbsp; <i className="fa fa-angle-down" />
          </NavbarSignoutButton>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <NavbarSignoutLink onClick={hotkeys}>Available Hotkeys</NavbarSignoutLink>
            <NavbarSignoutLink
              onClick={() => {
                logout();
              }}
            >
              Sign Out
            </NavbarSignoutLink>
          </div>
        </NavbarSignout>
        <HotkeysModal />
      </NavbarOuter>
    );
  } else {
    return "";
  }
};

export default NavbarNarrow;
